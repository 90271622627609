export default {
  formId: 'checkoutForm',
  formField: {
    studentName: {
      name: 'studentName',
      label: 'ছাত্র/ছাত্রীর নাম*',
      requiredErrorMsg: 'ছাত্র/ছাত্রীর নাম আবশ্যক'
    },
    rollNo: {
      name: 'rollNo',
      label: 'ক্লাস রোল নম্বর*',
      requiredErrorMsg: 'ক্লাস রোল নম্বর আবশ্যক'
    },
    className: {
      name: 'className',
      label: 'শ্রেণী*',
      requiredErrorMsg: 'শ্রেণী আবশ্যক'
    },
    department: {
      name: 'department',
      label: 'বিভাগ',
      requiredErrorMsg: 'বিভাগ আবশ্যক'
    },
    progressionYear: {
      name: 'progressionYear',
      label: 'বর্ষ*',
      requiredErrorMsg: 'বর্ষ আবশ্যক'
    },
    academicSession: {
      name: 'academicSession',
      label: 'সেশন*',
      requiredErrorMsg: 'সেশন আবশ্যক'
    },
    residential: {
      name: 'residential',
      label: 'আবাসিক',

    },
    mobile: {
      name: 'mobile',
      label: 'মোবাইল*',
      requiredErrorMsg: 'মোবাইল আবশ্যক'
    },
    email: {
      name: 'email',
      label: 'ইমেইল ',
      requiredErrorMsg: 'ইমেইল আবশ্যক'
    },
    admission: {
      name: 'admission',
      label: 'ভর্তি ফিস',

    },
    reAdmission: {
      name: 'reAdmission',
      label: 'পুনঃভর্তি ফিস',

    },
    tuition: {
      name: 'tuition',
      label: 'বেতন',
    },
    late: {
      name: 'late',
      label: 'বিলম্ব ফিস',


    },
    admissionAssistance: {
      name: 'admissionAssistance',
      label: 'ভর্তি সহায়ক ফিস',


    },
    admissionList: {
      name: 'admissionList',
      label: 'তালিকা ভর্তি ফিস',


    },
    session: {
      name: 'session',
      label: 'সেশন ফিস',


    },
    hallUnion: {
      name: 'hallUnion',
      label: 'হল ইউনিয়ন ফিস',


    },
    hallSport: {
      name: 'hallSport',
      label: 'হল ক্রীড়া ফিস',


    },
    hallAdmission: {
      name: 'hallAdmission',
      label: 'হল ভর্তি ফিস',


    },
    hallSeat: {
      name: 'hallSeat',
      label: 'সিট ভাড়া',


    },
    establishment: {
      name: 'establishment',
      label: 'সংস্থাপন খড়চ',


    },
    accessories: {
      name: 'accessories',
      label: 'বাসন কোসন ফিস',


    },
    idCard: {
      name: 'idCard',
      label: 'পরিচয় পত্র  ফিস',


    },
    library: {
      name: 'library',
      label: 'গ্রন্থাগার ফিস',


    },
    laboratory: {
      name: 'laboratory',
      label: 'ল্যাবেরটরি ফিস',


    },
    libraryCard: {
      name: 'libraryCard',
      label: 'গ্রন্থাগার কার্ড ফিস',


    },
    studentHelpingFund: {
      name: 'studentHelpingFund',
      label: 'ছাত্র সাহায্য তহবিল',


    },
    studentWelfareFund: {
      name: 'studentWelfareFund',
      label: 'ছাত্র কল্যান তহবিল',


    },
    studentWelfare: {
      name: 'studentWelfare',
      label: 'ছাত্র কল্যান ফিস',


    },
    medical: {
      name: 'medical',
      label: 'চিকিংসা ফিস',


    },
    roverScout: {
      name: 'roverScout',
      label: 'রোভার স্কাউট ফিস',


    },
    transport: {
      name: 'transport',
      label: 'পরিবহন ফিস',


    },
    nonCollegiate: {
      name: 'nonCollegiate',
      label: 'নন কলেজিয়েট ফিস',


    },
    uss: {
      name: 'uss',
      label: 'উস ফিস',


    },
    cartularyNo: {
      name: 'cartularyNo',
      label: 'নম্বর ফর্দ পরীক্ষা ফিস',


    },
    bncc: {
      name: 'bncc',
      label: 'বি.এন.সি.সি ফিস',


    },
    registration: {
      name: 'registration',
      label: 'রেজিস্ট্রেশন ফিস',


    },
    universityUnion: {
      name: 'universityUnion',
      label: 'বিশ্ববিদ্যালয় ইউনিয়ন ফিস',


    },
    universitySport: {
      name: 'universitySport',
      label: 'বিশ্ববিদ্যালয় ক্রীড়া',


    },
    exam: {
      name: 'exam',
      label: 'পরীক্ষার ফিস',


    },
    residentVisiting: {
      name: 'residentVisiting',
      label: 'আবাস পরিদর্শন ফিস',


    },
    educationDevelopment: {
      name: 'educationDevelopment',
      label: 'শিক্ষা উন্নয়ন ফিস',


    },
    retention: {
      name: 'retention',
      label: 'রিটেনসান ফিস',


    },
    others: {
      name: 'others',
      label: 'অন্যান্য ফি',


    },

  }
};
