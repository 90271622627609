import React from 'react';
import CheckoutPage from '../components/CheckoutPage/CheckoutPage';
import MaterialLayout from '../components/Layout/MaterialLayout';
const PaymentPage = () => {
    return (
        <div>
            <MaterialLayout>
                <CheckoutPage />
            </MaterialLayout>
        </div>
    );
};

export default PaymentPage;