import { Box, Button, Grid, Typography } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import generatePDF from 'react-to-pdf';
import cuLogo from '../../../assets/logo_cu.png';
import MaterialLayout from '../../Layout/MaterialLayout';
import ProductDetails from '../ReviewOrder/ProductDetails';
import ShippingDetails from '../ReviewOrder/ShippingDetails';
import useStyles from './styles';
const PaymentStatus = () => {
    const classes = useStyles();
    const urlParams = new URLSearchParams(window.location.search);
    const data = {};
    for (const [key, value] of urlParams.entries()) {
        data[key] = value;
    }
    const startIndex = data?.studentFee?.indexOf("StudentFee(");
    const endIndex = data?.studentFee?.lastIndexOf(")");
    let extractedContent = data?.studentFee?.slice(startIndex + "StudentFee(".length, endIndex);
    extractedContent = extractedContent?.replace("student=Student(", "");
    extractedContent = extractedContent?.substring(0, extractedContent.lastIndexOf(")") - 1);
    const keyValuePairs = extractedContent?.split(', ');
    const jsonData = {};
    if (keyValuePairs) {
        for (const pair of keyValuePairs) {
            const [key, value] = pair?.split('=');
            let parsedValue;
            if (/^[\d.]+$/.test(value)) {
                parsedValue = parseFloat(value);
            } else {
                parsedValue = value;
            }
            jsonData[key] = parsedValue === 'null' ? null : parsedValue;
        }
    }

    delete data.studentFee;
    const result = {
        ...data,
        ...jsonData,
    }
    const date = new Date(result?.createdAt);

    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    const formattedDate = date.toLocaleDateString('en-US', options);
    const spCode = result?.txStatusDetails?.match(/spcode: (\d+)/);
    const options1 = {
        filename: `${result.studentName}.pdf`,
        method: "save",

        page: {
            margin: 30,
        },
        canvas: {
            mimeType: "image/jpeg",
            qualityRatio: 1
        },
        overrides: {
            pdf: {
                compress: true
            },
            canvas: {
                useCORS: true
            }
        }
    };
    const getTargetElement = () => document.getElementById("container");

    const downloadPdf = () => generatePDF(getTargetElement, options1);
    return (
        <div >
            <MaterialLayout>
                {spCode && spCode[1] == 1000 ? (
                    <>
                        <div style={{ textAlign: 'right' }}>
                            <Button onClick={downloadPdf}>
                                <DownloadIcon />
                            </Button>
                        </div>


                        <div id="container">
                            <Typography component="h1" variant="h4" align="center">
                                <img
                                    src={cuLogo}
                                    alt=""
                                    style={{
                                        width: '15%',
                                        display: 'block',
                                        margin: '0 auto',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </Typography>
                            <ShippingDetails formValues={result} />

                            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                                পেমেন্ট স্ট্যাটাস
                            </Typography>
                            <Grid item xs={12} >
                                <Grid container >

                                    <Grid item xs={6}>
                                        <Typography gutterBottom>পেমেন্ট মাধ্যম</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography style={{ textAlign: 'right' }}>shurjoPay - {result.paymentChannel}</Typography>
                                    </Grid>

                                    <Grid item xs={6} >
                                        <Typography gutterBottom>ট্রানজেকশন আইডি</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography gutterBottom style={{ textAlign: 'right' }}>{result.spTrxnId}</Typography>
                                    </Grid>

                                    <Grid item xs={6} >
                                        <Typography gutterBottom >ট্রানজেকশন পরিমান</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography gutterBottom style={{ textAlign: 'right' }}>{result.txAmount} ({result.txCurrency})</Typography>
                                    </Grid>

                                    <Grid item xs={6} >
                                        <Typography gutterBottom >ট্রানজেকশন সময়</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Typography gutterBottom style={{ textAlign: 'right' }}>{formattedDate}</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <ProductDetails formValues={result} />
                        </div>
                    </>) : (
                    <>  <Typography component="h1" variant="h4" align="center">
                        <img
                            src={cuLogo}
                            alt=""
                            style={{
                                width: '15%',
                                display: 'block',
                                margin: '0 auto',
                                verticalAlign: 'middle',
                            }}
                        />
                    </Typography>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            minHeight="74.6vh"
                        >

                            <ReportGmailerrorredOutlinedIcon color="error" fontSize="large" />
                            <Typography variant="h5" color="error" gutterBottom>
                                Payment Failed
                            </Typography>
                            <Typography color="textSecondary">
                                Your payment was not successful. Please try again later.
                            </Typography>
                            <Button
                                component={Link}
                                to="/"
                                variant="contained"
                                style={{ backgroundColor: 'grey', color: 'white', marginTop: '20px' }}
                            >
                                <Typography variant="button" style={{ color: 'white' }}>
                                    Retry Payment
                                </Typography>
                            </Button>
                        </Box>
                    </>
                )}
            </MaterialLayout>
        </div>
    );
};

export default PaymentStatus;