import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    studentName,
    email,
    mobile,
    className,
    rollNo,
    department,
    progressionYear,
    academicSession,
    residential,
    admission,
    reAdmission,
    tuition,
    late,
    admissionAssistance,
    admissionList,
    session,
    hallUnion,
    hallSport,
    hallAdmission,
    hallSeat,
    establishment,
    accessories,
    idCard,
    library,
    laboratory,
    libraryCard,
    studentHelpingFund,
    studentWelfareFund,
    studentWelfare,
    medical,
    roverScout,
    transport,
    nonCollegiate,
    uss,
    cartularyNo,
    bncc,
    registration,
    universityUnion,
    universitySport,
    exam,
    residentVisiting,
    educationDevelopment,
    retention,
    others,
  }
} = checkoutFormModel;

export default {
  [studentName.name]: '',
  [email.name]: '',
  [mobile.name]: '',
  [className.name]: '',
  [rollNo.name]: '',
  [department.name]: '',
  [progressionYear.name]: '',
  [academicSession.name]: '',
  [residential.name]: false,
  [admission.name]: 0,
  [reAdmission.name]: 0,
  [tuition.name]: 0,
  [late.name]: 0,
  [admissionAssistance.name]: 0,
  [admissionList.name]: 0,
  [session.name]: 0,
  [hallUnion.name]: 0,
  [hallSport.name]: 0,
  [hallAdmission.name]: 0,
  [hallSeat.name]: 0,
  [establishment.name]: 0,
  [accessories.name]: 0,
  [idCard.name]: 0,
  [library.name]: 0,
  [laboratory.name]: 0,
  [libraryCard.name]: 0,
  [studentHelpingFund.name]: 0,
  [studentWelfareFund.name]: 0,
  [studentWelfare.name]: 0,
  [medical.name]: 0,
  [roverScout.name]: 0,
  [transport.name]: 0,
  [nonCollegiate.name]: 0,
  [uss.name]: 0,
  [cartularyNo.name]: 0,
  [bncc.name]: 0,
  [registration.name]: 0,
  [universityUnion.name]: 0,
  [universitySport.name]: 0,
  [exam.name]: 0,
  [residentVisiting.name]: 0,
  [educationDevelopment.name]: 0,
  [retention.name]: 0,
  [others.name]: 0,

};
