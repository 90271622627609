import { CssBaseline, Paper } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import bgimage from '../../assets/cu_bg.png';
import Footer from '../Footer';

import { theme, useStyle } from './styles';

export default function MaterialLayout(props) {
  const { children } = props;
  const classes = useStyle();

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ backgroundImage: `url(${bgimage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', paddingTop: 10, paddingBottom: 247 }}>
        {/* <Header /> */}
        {/* <div className={classes.root}> */}
        <Paper className={classes.paper} style={{ width: 600, marginLeft: 'auto', marginRight: 'auto', }}>
          {children}
        </Paper>
        {/* </div> */}
        <Footer />
      </div>
    </ThemeProvider>


  );
}
