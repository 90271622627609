import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PaymentStatus from "./components/CheckoutPage/CheckoutSuccess/PaymentStatus";
import PaymentPage from "./pages/PaymentPage";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PaymentPage />} />
          <Route path="/payments/status" element={<PaymentStatus />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
