import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { InputField } from '../../FormFields';

export default function PaymentForm(props) {
  const {
    formField: {  
      admission,
      reAdmission,
      tuition,
      late,
      admissionAssistance,
      admissionList,
      session,
      hallUnion,
      hallSport,
      hallAdmission,
      hallSeat,
      establishment,
      accessories,
      idCard,
      library,
      laboratory,
      libraryCard,
      studentHelpingFund,
      studentWelfareFund,
      studentWelfare,
      medical,
      roverScout,
      transport,
      nonCollegiate,
      uss,
      cartularyNo,
      bncc,
      registration,
      universityUnion,
      universitySport,
      exam,
      residentVisiting,
      educationDevelopment,
      retention,
      others, }
  } = props;

  return (
    <React.Fragment>
    <Typography variant="h6" gutterBottom>
    ভর্তি ফিস 
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InputField
          name={admission.name}
          label={admission.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={reAdmission.name}
          label={reAdmission.label}
          type="number"
          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={tuition.name}
          label={tuition.label}
          type="number"
          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={late.name}
          label={late.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={admissionAssistance.name}
          label={admissionAssistance.label}
          type="number"
          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={admissionList.name}
          label={admissionList.label}
          type="number"
          fullWidth
         
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={session.name}
          label={session.label}
          type="number"
          fullWidth
         
        />
      </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
      আবাসিক/হল ফি
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InputField
          name={hallUnion.name}
          label={hallUnion.label}
          type="number"
          fullWidth
         
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={hallSport.name}
          label={hallSport.label}
          type="number"
          fullWidth
         
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={hallAdmission.name}
          label={hallAdmission.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={hallSeat.name}
          label={hallSeat.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={establishment.name}
          label={establishment.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={accessories.name}
          label={accessories.label}
          type="number"
          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={idCard.name}
          label={idCard.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      

      </Grid>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
      গ্রন্থাগার ফিস
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InputField
          name={library.name}
          label={library.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={laboratory.name}
          label={laboratory.label}
          type="number"
          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={libraryCard.name}
          label={libraryCard.label}
          type="number"
          fullWidth
       
        />
      </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
      ছাত্র কল্যান তহবিল
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InputField
          name={studentHelpingFund.name}
          label={studentHelpingFund.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={studentWelfareFund.name}
          label={studentWelfareFund.label}
          type="number"
          fullWidth
         
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={studentWelfare.name}
          label={studentWelfare.label}
          type="number"

          fullWidth
     
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={medical.name}
          label={medical.label}
          type="number"

          fullWidth

        />
      </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
      অন্যান্য ফিস
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InputField
          name={roverScout.name}
          label={roverScout.label}
          type="number"

          fullWidth
      
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={transport.name}
          label={transport.label}
          type="number"

          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={nonCollegiate.name}
          label={nonCollegiate.label}
          type="number"

          fullWidth
      
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={uss.name}
          label={uss.label}
          type="number"

          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={cartularyNo.name}
          label={cartularyNo.label}
          type="number"

          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={bncc.name}
          label={bncc.label}
          type="number"
          fullWidth
     
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={registration.name}
          label={registration.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={universityUnion.name}
          type="number"
          label={universityUnion.label}
          fullWidth
         
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={universitySport.name}
          label={universitySport.label}
          type="number"
          fullWidth
         
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={exam.name}
          label={exam.label}
          type="number"
          fullWidth
          
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={residentVisiting.name}
          label={residentVisiting.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={educationDevelopment.name}
          label={educationDevelopment.label}
          type="number"
          fullWidth
        
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={retention.name}
          label={retention.label}
          type="number"
          fullWidth
       
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={others.name}
          label={others.label}
          type="number"
          fullWidth
         
        />
      </Grid>

    </Grid>
  </React.Fragment>
  );
}
