import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

function PaymentDetails(props) {
  const { formValues } = props;
  const classes = useStyles();

  const { studentName, className, rollNo, email, department,
    progressionYear, academicSession, mobile } = formValues;
  return (
    <Grid item xs={12}  >
      <Typography variant="h6" gutterBottom className={classes.title}>
        ছাত্র/ছাত্রীর ডিটেইলস
      </Typography>
      <Grid container >
        <Grid item xs={6}>
          <Typography gutterBottom>নাম:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{studentName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>ক্লাস:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{className}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>রোল:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{rollNo}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>ইমেইল:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{email}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>সেশন:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{academicSession}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>ডিপার্টমেন্ট: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{department}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>একাডেমিক সাল: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{progressionYear}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>মোবাইল: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom>{mobile}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentDetails;
