import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

function ProductDetails(props) {
  const { formValues } = props;
  const { admission,
    reAdmission,
    tuition,
    late,
    admissionAssistance,
    admissionList,
    session,
    hallUnion,
    hallSport,
    hallAdmission,
    hallSeat,
    establishment,
    accessories,
    idCard,
    library,
    laboratory,
    libraryCard,
    studentHelpingFund,
    studentWelfareFund,
    studentWelfare,
    medical,
    roverScout,
    transport,
    nonCollegiate,
    uss,
    cartularyNo,
    bncc,
    registration,
    universityUnion,
    universitySport,
    exam,
    residentVisiting,
    educationDevelopment,
    retention,
    others, } = formValues;

  const createdArray = [
    {
      name: "ভর্তি ফিস",
      value: admission
    },
    {
      name: "পুনঃভর্তি ফিস",
      value: reAdmission
    },
    {
      name: "বেতন ",
      value: tuition
    }, {
      name: "বিলম্ব ফিস",
      value: late
    },
    {
      name: "ভর্তি সহায়ক ফিস",
      value: admissionAssistance
    },
    {
      name: "তালিকা ভর্তি ফিস",
      value: admissionList
    },
    {
      name: "সেশন ফিস",
      value: session
    },
    {
      name: "হল ইউনিয়ন ফিস",
      value: hallUnion
    }, {
      name: "হল ক্রীড়া ফিস",
      value: hallSport
    },
    {
      name: "হল ভর্তি ফিস",
      value: hallAdmission
    }, {
      name: "সিট ভাড়া",
      value: hallSeat
    },
    {
      name: "সংস্থাপন খড়চ",
      value: establishment
    },
    {
      name: "বাসন কোসন ফিস",
      value: accessories
    },
    {
      name: "পরিচয় পত্র  ফিস",
      value: idCard
    },
    {
      name: "গ্রন্থাগার ফিস",
      value: library
    },
    {
      name: "ল্যাবেরটরি ফিস",
      value: laboratory
    },
    {
      name: "গ্রন্থাগার কার্ড ফিস",
      value: libraryCard
    },
    {
      name: "ছাত্র সাহায্য তহবিল",
      value: studentHelpingFund
    },
    {
      name: "ছাত্র কল্যান তহবিল",
      value: studentWelfareFund
    },
    {
      name: "ছাত্র কল্যান ",
      value: studentWelfare
    },
    {
      name: "চিকিংসা ফিস",
      value: medical
    },
    {
      name: "রোভার স্কাউট ফিস",
      value: roverScout
    },
    {
      name: "পরিবহন ফিস",
      value: transport
    },
    {
      name: "নন কলেজিয়েট ফিস",
      value: nonCollegiate
    },
    {
      name: "উস ফিস",
      value: uss
    },
    {
      name: "নম্বর ফর্দ পরীক্ষা ফিস",
      value: cartularyNo
    },
    {
      name: "বি.এন.সি.সি ফিস",
      value: bncc
    },
    {
      name: "রেজিস্ট্রেশন ফিস",
      value: registration
    },
    {
      name: "বিশ্ববিদ্যালয় ইউনিয়ন ফিস",
      value: universityUnion
    },
    {
      name: "বিশ্ববিদ্যালয় ক্রীড়া ফিস",
      value: universitySport
    },
    {
      name: "পরীক্ষার ফিস",
      value: exam
    },
    {
      name: "আবাস পরিদর্শন ফিস",
      value: residentVisiting
    },
    {
      name: "শিক্ষা উন্নয়ন ফিস",
      value: educationDevelopment
    },
    {
      name: "রিটেনসান ফিস",
      value: retention
    },
    {
      name: "অন্যান্য ফি",
      value: others
    }

  ]

  const filteredArray = createdArray.filter(item => item.value !== null && item.value !== 0 && item.value !== undefined);
  const classes = useStyles();

  const total = filteredArray.reduce((accumulator, item) => accumulator + item.value, 0);
  return (
    <>
      <Typography variant="h6" gutterBottom>
        পেমেন্ট ডিটেইলস
      </Typography>
      <List disablePadding>

        {filteredArray.map((item) => (
          <ListItem key={item.name} className={classes.listItem}>
            <ListItemText primary={item.name} />
            <Typography>{item.value}</Typography>
          </ListItem>
        ))}

        <ListItem className={classes.listItem}>
          <ListItemText primary="মোট টাকা" />
          <Typography variant="subtitle1" className={classes.total}>
            {total.toFixed(2)}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary="প্রসেসিং ফি" />
          <Typography variant="subtitle2" className={classes.process}>
            {(1.14 / 100 * total).toFixed(2)}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="সর্বমোট" />
          <Typography variant="subtitle3" className={classes.stotal}>
            {(1.14 / 100 * total + total).toFixed(2)}
          </Typography>
        </ListItem>
      </List>
    </>
  );
}

export default ProductDetails;
