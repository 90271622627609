import { Link, Typography } from '@material-ui/core/';
import React from 'react';

export default function Footer() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="//shurjomukhi.com.bd">
        shurjoMukhi Limited | shurjoPay  
      </Link>
     {" "} {new Date().getFullYear()}
    </Typography>
  );
}
