import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    studentName,
    email,
    mobile,
    className,
    rollNo,
    department,
    progressionYear,
    academicSession,
    residential,
    admission,
    reAdmission,
    tuition,
    late,
    admissionAssistance,
    admissionList,
    session,
    hallUnion,
    hallSport,
    hallAdmission,
    hallSeat,
    establishment,
    accessories,
    idCard,
    library,
    laboratory,
    libraryCard,
    studentHelpingFund,
    studentWelfareFund,
    studentWelfare,
    medical,
    roverScout,
    transport,
    nonCollegiate,
    uss,
    cartularyNo,
    bncc,
    registration,
    universityUnion,
    universitySport,
    exam,
    residentVisiting,
    educationDevelopment,
    retention,
    others,
  }
} = checkoutFormModel;


export default [
  Yup.object().shape({
    [studentName.name]: Yup.string().required(`${studentName.requiredErrorMsg}`),
    [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
    [mobile.name]: Yup.string().required(`${mobile.requiredErrorMsg}`),
    [className.name]: Yup.string().required(`${className.requiredErrorMsg}`),
    [rollNo.name]: Yup.string().required(`${rollNo.requiredErrorMsg}`),
    [department.name]: Yup.string().required(`${department.requiredErrorMsg}`),
    [progressionYear.name]: Yup.string().required(`${progressionYear.requiredErrorMsg}`),
    [academicSession.name]: Yup.string().required(`${academicSession.requiredErrorMsg}`),
    [residential.name]: Yup.boolean(),
    [admission.name]: Yup.number(),
    [reAdmission.name]: Yup.number(),
    [tuition.name]: Yup.number(),
    [late.name]: Yup.number(),
    [admissionAssistance.name]: Yup.number(),
    [admissionList.name]: Yup.number(),
    [session.name]: Yup.number(),
    [hallUnion.name]: Yup.number(),
    [hallSport.name]: Yup.number(),
    [hallAdmission.name]: Yup.number(),
    [hallSeat.name]: Yup.number(),
    [establishment.name]: Yup.number(),
    [accessories.name]: Yup.number(),
    [idCard.name]: Yup.number(),
    [library.name]: Yup.number(),
    [laboratory.name]: Yup.number(),
    [libraryCard.name]: Yup.number(),
    [studentHelpingFund.name]: Yup.number(),
    [studentWelfareFund.name]: Yup.number(),
    [studentWelfare.name]: Yup.number(),
    [medical.name]: Yup.number(),
    [roverScout.name]: Yup.number(),
    [transport.name]: Yup.number(),
    [nonCollegiate.name]: Yup.number(),
    [uss.name]: Yup.number(),
    [cartularyNo.name]: Yup.number(),
    [bncc.name]: Yup.number(),
    [registration.name]: Yup.number(),
    [universityUnion.name]: Yup.number(),
    [universitySport.name]: Yup.number(),
    [exam.name]: Yup.number(),
    [residentVisiting.name]: Yup.number(),
    [educationDevelopment.name]: Yup.number(),
    [retention.name]: Yup.number(),
    [others.name]: Yup.number(),

  })

];
