import { useFormikContext } from 'formik';
import React from 'react';
import ProductDetails from './ProductDetails';
import ShippingDetails from './ShippingDetails';

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  return (
    <React.Fragment>
      <ShippingDetails formValues={formValues} />
      <ProductDetails formValues={formValues}/>
    </React.Fragment>
  );
}
