import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import AddressForm from './Forms/AddressForm';
import PaymentForm from './Forms/PaymentForm';
import ReviewOrder from './ReviewOrder';

import axios from 'axios';
import cuLogo from '../../assets/logo_cu.png';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';
import validationSchema from './FormModel/validationSchema';
import useStyles from './styles';

const steps = ['ছাত্র/ছাত্রীর তথ্য', 'ফি সক্রান্ত তথ্য', 'তথ্য পুনঃমূল্যায়ন'];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm formField={formField} />;
    case 1:
      return <PaymentForm formField={formField} />;
    case 2:
      return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CheckoutPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    try {
      console.log(values.residential);
      const addStudent = await axios.post(`${process.env.REACT_APP_BASE_URL}/students`, values);


      const payValue = {
        ...values,
        student: {
          id: addStudent.data.id,
        },
      };
      const sumOfValues = Object.values(values).reduce((total, value) => {
        if (typeof value === 'number') {
          return total + value;
        }
        return total;
      }, 0);
      const addPayment = await axios.post(`${process.env.REACT_APP_BASE_URL}/student-fee`, {
        ...payValue,
        totalFee: sumOfValues,
      });
      const payNow = await axios.get(`${process.env.REACT_APP_BASE_URL}/payments/pay/${addPayment.data.id}`);

      if (!payNow.data) {
        console.error('No redirect link found in the response data.');
      } else if (sumOfValues < 10) {
        alert('Please select at least one item.');
        setActiveStep(activeStep - 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      } else {
        window.location.href = payNow.data;
      }

    } catch (error) {

      if (axios.isAxiosError(error)) {

        console.error('Axios Error:', error.response);

      } else {

        console.error('General Error:', error);

      }
    }
  }


  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>

      <Typography component="h1" variant="h4" align="center">
        <img
          src={cuLogo}
          alt=""
          style={{
            width: '15%',
            display: 'block',
            margin: '0 auto',
            verticalAlign: 'middle',
          }}
        />
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <></>
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? 'Pay Now' : 'Next'}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
