import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { CheckboxField, InputField } from '../../FormFields';




export default function AddressForm(props) {
  const {
    formField: {
      studentName,
      email,
      mobile,
      className,
      rollNo,
      department,
      progressionYear,
      academicSession,
      residential,
    }
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      ছাত্র/ছাত্রীর তথ্য
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <InputField type="text" name={studentName.name} label={studentName.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField type="email" name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField type="tel" name={mobile.name} label={mobile.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField type="text" name={className.name} label={className.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField type="text" name={rollNo.name}  label={rollNo.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField type="text" name={department.name} label={department.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField type="text" name={progressionYear.name} label={progressionYear.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField type="text" name={academicSession.name} label={academicSession.label} fullWidth />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <SelectField
            name={city.name}
            label={city.label}
            data={cities}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={state.name}
            label={state.label}
            data={states}
            fullWidth
          />
        </Grid> */}
    
        <Grid item xs={12}>
          <CheckboxField
            name={residential.name}
            label={residential.label}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
